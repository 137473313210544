import React from "react";

import ImageBgTitle from "../../assets/images/bg_title.svg";
import IconBack from "../../assets/images/icon_back.png";
import { Link } from "react-router-dom";
import httpClient from "../../services/httpClient";
import ImageCondition from "../../assets/images/condition.png";
import AlertNotify from "../../components/AlertNotify";
import ImageInvite from "../../assets/images/invite.jpg";

const PromotionPage = () => {
  const [promotions, setPromotions] = React.useState([]);

  const getData = async () => {
    try {
      const res1 = await httpClient.get("/main/getPromotions");
      setPromotions(res1.data.data);
    } catch (error) {
    } finally {
    }
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <AlertNotify />
      <div className="bg_title bg_title_sm">
        <img src={ImageBgTitle} className="bgSM" alt="ImageBgTitle" />
        <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
          เงื่อนไขการถอนและโปรโมชั่น
        </span>
        <Link to="/main" className="icon_back ic_back_sm">
          <img src={IconBack} alt="IconBack" />
        </Link>
      </div>
      <div className="box_Border_Content">
        <div className="box_Content box_Content2">
          <a href="!#" className="banner_pro">
            <img src={ImageCondition} className="img-fluid" alt="imag" />
          </a>
        </div>
      </div>
      {promotions
        .slice()
        .reverse()
        .map((item, index) => {
          return (
            <div className="box_Border_Content" key={index}>
              <div className="box_Content box_Content2">
                <a href="!#" className="banner_pro">
                  <img src={item.image} className="img-fluid" alt="imag" />
                </a>
              </div>
            </div>
          );
        })}
      <div className="box_Border_Content">
        <div className="box_Content box_Content2">
          <a href="!#" className="banner_pro">
            <img src={ImageInvite} className="img-fluid" alt="imag" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PromotionPage;
